import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/www/src/templates/default-mdx-page-template.tsx";
import { graphql } from 'gatsby';
import { Table } from '~paragon-react';
import getCssSelectors from '../../utils/getCssSelectors.ts';
import CSSUtilitiesTable from '../../components/CSSUtilitiesTable';
import LayoutGenerator from '../../components/LayoutGenerator';
export const pageQuery = graphql`
{
  flexUtilities: allCssUtilityClasses(filter: {declarations: {regex: "/flex/"}, isUtility: {eq: true}}) {
    nodes {
      selector
      declarations
    }
  }
  displayUtilities: allCssUtilityClasses(filter: {declarations: {regex: "/display/"}, isUtility: {eq: true}}) {
    nodes {
      selector
      declarations
    }
  }
  positionUtilities: allCssUtilityClasses(filter: {selector: {regex: "/(^fixed-)|(position)/"}}) {
    nodes {
      selector
      declarations
    }
  }
  maxWidthUtilities: allCssUtilityClasses(filter: {selector: {regex: "/(^mw-)/"}}) {
    nodes {
      selector
      declarations
    }
  }
  miscUtilities: allCssUtilityClasses(filter: {declarations: {regex: "/(float)|(overflow)/"}, isUtility: {eq: true}}) {
    nodes {
      selector
      declarations
    }
  }
}
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "layout"
    }}>{`Layout`}<a parentName="h1" {...{
        "aria-hidden": "true",
        "tabIndex": -1,
        "href": "#layout"
      }}><span parentName="a" {...{
          "className": "pgn-doc__anchor"
        }}>{`#`}</span></a></h1>
    <p>{`Paragon's layout is controlled by the Bootstrap grid system. Documentation can be found here:
`}<a parentName="p" {...{
        "href": "https://getbootstrap.com/docs/4.5/layout/grid/"
      }}>{`https://getbootstrap.com/docs/4.5/layout/grid/`}</a>{`. Also see `}<a parentName="p" {...{
        "href": "/components/layout"
      }}>{`Layout`}</a>{` component.`}</p>
    <h2 {...{
      "id": "components"
    }}>{`Components`}<a parentName="h2" {...{
        "aria-hidden": "true",
        "tabIndex": -1,
        "href": "#components"
      }}><span parentName="a" {...{
          "className": "pgn-doc__anchor"
        }}>{`#`}</span></a></h2>
    <p>
  These components are pass throughs from React-Bootstrap.<br />
  <a href="https://react-bootstrap-v4.netlify.app/layout/grid/" target="_blank" rel="noopener noreferrer">
    See React-Bootstrap for documentation.
  </a>
    </p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Container className="border">
  {/* Stack the columns on mobile by making one full-width and the other half-width */}
  <Row className="my-3">
    <Col xs={12} md={8}>
      <div className="p-3 border">xs=12 md=8</div>
    </Col>
    <Col xs={6} md={4}>
      <div className="p-3 border">xs=6 md=4</div>
    </Col>
  </Row>

  {/* Columns start at 50% wide on mobile and bump up to 33.3% wide on desktop */}
  <Row className="my-3">
    <Col xs={6} md={4}>
      <div className="p-3 border">xs=6 md=4</div>
    </Col>
    <Col xs={6} md={4}>
      <div className="p-3 border">xs=6 md=4</div>
    </Col>
    <Col xs={6} md={4}>
      <div className="p-3 border">xs=6 md=4</div>
    </Col>
  </Row>

  {/* Columns are always 50% wide, on mobile and desktop */}
  <Row className="my-3">
    <Col xs={6}>
      <div className="p-3 border">xs=6</div>
    </Col>
    <Col xs={6}>
      <div className="p-3 border">xs=6</div>
    </Col>
  </Row>
</Container>
`}</code></pre>
    <h2 {...{
      "id": "grid"
    }}>{`Grid`}<a parentName="h2" {...{
        "aria-hidden": "true",
        "tabIndex": -1,
        "href": "#grid"
      }}><span parentName="a" {...{
          "className": "pgn-doc__anchor"
        }}>{`#`}</span></a></h2>
    <LayoutGenerator mdxType="LayoutGenerator" />
    <h3 {...{
      "id": "row"
    }}>{`Row`}<a parentName="h3" {...{
        "aria-hidden": "true",
        "tabIndex": -1,
        "href": "#row"
      }}><span parentName="a" {...{
          "className": "pgn-doc__anchor"
        }}>{`#`}</span></a></h3>
    <p>{`Rows contain columns. They are `}<inlineCode parentName="p">{`display: flex`}</inlineCode>{` so
any related flex box styles or css utilities
can be leveraged here.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`.row`}</inlineCode></li>
    </ul>
    <h4 {...{
      "id": "related-flexbox-utilities"
    }}>{`Related flexbox utilities`}<a parentName="h4" {...{
        "aria-hidden": "true",
        "tabIndex": -1,
        "href": "#related-flexbox-utilities"
      }}><span parentName="a" {...{
          "className": "pgn-doc__anchor"
        }}>{`#`}</span></a></h4>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`.justify-content-start`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.justify-content-end`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.justify-content-center`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.justify-content-between`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.justify-content-around`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.align-items-start`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.align-items-end`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.align-items-center`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.align-items-baseline`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.align-items-stretch`}</inlineCode></li>
    </ul>
    <p>{`Responsive variations of these utilities also exist:
`}<inlineCode parentName="p">{`.{property}-{breakpoint}-{value}`}</inlineCode>{`.`}</p>
    <h4 {...{
      "id": "some-examples"
    }}>{`Some examples:`}<a parentName="h4" {...{
        "aria-hidden": "true",
        "tabIndex": -1,
        "href": "#some-examples"
      }}><span parentName="a" {...{
          "className": "pgn-doc__anchor"
        }}>{`#`}</span></a></h4>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`.justify-content-sm-start`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.align-items-md-start`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.align-items-l-between`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.justify-content-xl-between`}</inlineCode></li>
    </ul>
    <h3 {...{
      "id": "column"
    }}>{`Column`}<a parentName="h3" {...{
        "aria-hidden": "true",
        "tabIndex": -1,
        "href": "#column"
      }}><span parentName="a" {...{
          "className": "pgn-doc__anchor"
        }}>{`#`}</span></a></h3>
    <p>{`Columns lay on a 12 column grid.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`.col`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.col-\${width}`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.col-\${breakpoint}-\${width}`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`offset-\${columns}`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`offset-\${breakpoint}-\${columns}`}</inlineCode></li>
    </ul>
    <h4 {...{
      "id": "examples"
    }}>{`Examples`}<a parentName="h4" {...{
        "aria-hidden": "true",
        "tabIndex": -1,
        "href": "#examples"
      }}><span parentName="a" {...{
          "className": "pgn-doc__anchor"
        }}>{`#`}</span></a></h4>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`.col-1`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.col-2`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.col-3`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.col-4`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.col-5`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.col-6`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.col-7`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.col-8`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.col-9`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.col-10`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.col-11`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.col-12`}</inlineCode></li>
    </ul>
    <h4 {...{
      "id": "related-flexbox-utilities-1"
    }}>{`Related flexbox utilities`}<a parentName="h4" {...{
        "aria-hidden": "true",
        "tabIndex": -1,
        "href": "#related-flexbox-utilities-1"
      }}><span parentName="a" {...{
          "className": "pgn-doc__anchor"
        }}>{`#`}</span></a></h4>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`.align-self-start`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.align-self-end`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.align-self-center`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.align-self-baseline`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.align-self-stretch`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.flex-fill`}</inlineCode></li>
    </ul>
    <h3 {...{
      "id": "breakpoint-utilities"
    }}>{`Breakpoint Utilities`}<a parentName="h3" {...{
        "aria-hidden": "true",
        "tabIndex": -1,
        "href": "#breakpoint-utilities"
      }}><span parentName="a" {...{
          "className": "pgn-doc__anchor"
        }}>{`#`}</span></a></h3>
    <p>{`Many css utility classes have variants that apply
the style only at a certain breakpoint and above.`}</p>
    <ul>
      <li parentName="ul">{`Small and up `}<strong parentName="li"><inlineCode parentName="strong">{`-sm-`}</inlineCode></strong></li>
      <li parentName="ul">{`Medium and up `}<strong parentName="li"><inlineCode parentName="strong">{`-md-`}</inlineCode></strong></li>
      <li parentName="ul">{`Large and up `}<strong parentName="li"><inlineCode parentName="strong">{`-lg-`}</inlineCode></strong></li>
      <li parentName="ul">{`Extra Large and up `}<strong parentName="li"><inlineCode parentName="strong">{`-xl-`}</inlineCode></strong></li>
    </ul>
    <h4 {...{
      "id": "examples-1"
    }}>{`Examples`}<a parentName="h4" {...{
        "aria-hidden": "true",
        "tabIndex": -1,
        "href": "#examples-1"
      }}><span parentName="a" {...{
          "className": "pgn-doc__anchor"
        }}>{`#`}</span></a></h4>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`.col-sm-4`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.col-md-4`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.col-lg-4`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.col-xl-4`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.align-items-sm-start`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.align-items-md-start`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.align-items-lg-start`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.align-items-xl-start`}</inlineCode></li>
    </ul>
    <h3 {...{
      "id": "display-utilities"
    }}>{`Display Utilities`}<a parentName="h3" {...{
        "aria-hidden": "true",
        "tabIndex": -1,
        "href": "#display-utilities"
      }}><span parentName="a" {...{
          "className": "pgn-doc__anchor"
        }}>{`#`}</span></a></h3>
    <CSSUtilitiesTable selectors={getCssSelectors(props.data.displayUtilities.nodes)} mdxType="CSSUtilitiesTable" />
    <h3 {...{
      "id": "position"
    }}>{`Position`}<a parentName="h3" {...{
        "aria-hidden": "true",
        "tabIndex": -1,
        "href": "#position"
      }}><span parentName="a" {...{
          "className": "pgn-doc__anchor"
        }}>{`#`}</span></a></h3>
    <CSSUtilitiesTable selectors={getCssSelectors(props.data.positionUtilities.nodes)} mdxType="CSSUtilitiesTable" />
    <h3 {...{
      "id": "flexbox"
    }}>{`Flexbox`}<a parentName="h3" {...{
        "aria-hidden": "true",
        "tabIndex": -1,
        "href": "#flexbox"
      }}><span parentName="a" {...{
          "className": "pgn-doc__anchor"
        }}>{`#`}</span></a></h3>
    <CSSUtilitiesTable selectors={getCssSelectors(props.data.flexUtilities.nodes)} mdxType="CSSUtilitiesTable" />
    <h3 {...{
      "id": "max-width"
    }}>{`Max Width`}<a parentName="h3" {...{
        "aria-hidden": "true",
        "tabIndex": -1,
        "href": "#max-width"
      }}><span parentName="a" {...{
          "className": "pgn-doc__anchor"
        }}>{`#`}</span></a></h3>
    <CSSUtilitiesTable selectors={getCssSelectors(props.data.maxWidthUtilities.nodes)} mdxType="CSSUtilitiesTable" />
    <h3 {...{
      "id": "misc-layout"
    }}>{`Misc Layout`}<a parentName="h3" {...{
        "aria-hidden": "true",
        "tabIndex": -1,
        "href": "#misc-layout"
      }}><span parentName="a" {...{
          "className": "pgn-doc__anchor"
        }}>{`#`}</span></a></h3>
    <CSSUtilitiesTable selectors={getCssSelectors(props.data.miscUtilities.nodes)} mdxType="CSSUtilitiesTable" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      